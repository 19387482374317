@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
  font-family: 'Ploni-Black';
  src: url('src/assets/fonts/ploni-black-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Bold';
  src: url('src/assets/fonts/ploni-bold-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Demibold';
  src: url('src/assets/fonts/ploni-demibold-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Light';
  src: url('src/assets/fonts/ploni-light-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Medium';
  src: url('src/assets/fonts/ploni-medium-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Regular';
  src: url('src/assets/fonts/ploni-regular-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Ultrabold';
  src: url('src/assets/fonts/ploni-ultrabold-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni-Ultralight';
  src: url('src/assets/fonts/ploni-ultralight-aaa.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Short-Xurkit-Tilt';
  src: url('src/assets/fonts/short-xurkit-tilt.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Motaleh-Bold-Fm';
  src: url('src/assets/fonts/motaleh-bold-fm.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Almoni-DL-AAA-Bold';
  src: url('src/assets/fonts/almoni-dl-aaa-bold-webfont.woff');
  font-weight: normal;
  font-style: normal;
}
