@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@font-face {
  font-family: "Ploni-Black";
  src: url("src/assets/fonts/ploni-black-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Bold";
  src: url("src/assets/fonts/ploni-bold-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Demibold";
  src: url("src/assets/fonts/ploni-demibold-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Light";
  src: url("src/assets/fonts/ploni-light-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Medium";
  src: url("src/assets/fonts/ploni-medium-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Regular";
  src: url("src/assets/fonts/ploni-regular-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Ultrabold";
  src: url("src/assets/fonts/ploni-ultrabold-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Ultralight";
  src: url("src/assets/fonts/ploni-ultralight-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Short-Xurkit-Tilt";
  src: url("src/assets/fonts/short-xurkit-tilt.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Motaleh-Bold-Fm";
  src: url("src/assets/fonts/motaleh-bold-fm.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Almoni-DL-AAA-Bold";
  src: url("src/assets/fonts/almoni-dl-aaa-bold-webfont.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Black";
  src: url("src/assets/fonts/ploni-black-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Bold";
  src: url("src/assets/fonts/ploni-bold-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Demibold";
  src: url("src/assets/fonts/ploni-demibold-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Light";
  src: url("src/assets/fonts/ploni-light-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Medium";
  src: url("src/assets/fonts/ploni-medium-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Regular";
  src: url("src/assets/fonts/ploni-regular-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Ultrabold";
  src: url("src/assets/fonts/ploni-ultrabold-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ploni-Ultralight";
  src: url("src/assets/fonts/ploni-ultralight-aaa.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Short-Xurkit-Tilt";
  src: url("src/assets/fonts/short-xurkit-tilt.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Motaleh-Bold-Fm";
  src: url("src/assets/fonts/motaleh-bold-fm.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Almoni-DL-AAA-Bold";
  src: url("src/assets/fonts/almoni-dl-aaa-bold-webfont.woff");
  font-weight: normal;
  font-style: normal;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Ploni-Regular";
}

div {
  box-sizing: border-box;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
  padding: 0;
  color: inherit;
  background-color: transparent;
}

p {
  margin: 0;
}

object {
  position: absolute;
}

/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('small') {
			width: 90%;
		}
		@include screen('medim') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */