@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.category-winner {
  padding-top: 42px;
  &-wrapper {
    display: flex;
    align-items: flex-start;
    max-width: 1380px;
    padding: 0 20px;
    margin: 0 auto;

    @include screen('desktop_annual_lg') {
      flex-wrap: wrap;
      padding: 0;
    }

    @include screen('mobile_annual_xl') {
      flex-wrap: wrap;
    }

    .cover-image {
      width: 50%;
      min-width: 50%;
      padding-left: 5px;

      @include screen('desktop_annual_lg') {
        width: 100%;
        max-width: 320px;
        min-width: inherit;
        margin: 0 auto;
      }

      @include screen('mobile_annual_xl') {
        width: 100%;
        max-width: 320px;
        min-width: inherit;
        margin: 0 auto;
        padding-bottom: 24px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .info {
      width: 50%;
      max-width: 50%;
      padding-right: 5px;
      @include screen('desktop_annual_lg') {
        width: 100%;
        max-width: inherit;
        padding: 0 100px 50px;
      }

      @include screen('mobile_annual_xl') {
        width: 100%;
        max-width: 662px;
        padding: 0 15px 50px;
        margin: -10px auto 0;
      }

      .title-image {
        padding-top: 60px;
        padding-bottom: 36.57px;
        @include screen('mobile_annual_xl') {
          display: none;
          // padding-bottom: 24px;
        }
        img {
          display: block;
          width: 100%;
          max-width: 482.5px;
          @include screen('desktop_annual_lg') {
            max-width: 320.5px;
            margin: 0 auto;
          }
          @include screen('mobile_annual_xl') {
            max-width: 320.5px;
            margin: 0 auto;
          }
        }
      }

      .description {
        font-size: 20.11px;
        line-height: 25.6px;
        color: #fff;
        font-family: $main-font_medium;
        @include screen('mobile_annual_xl') {
          font-size: 22px;
          line-height: 24px;
        }
      }
    }
  }
}
