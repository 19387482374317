@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.banner-wrap {
  display: none;
  background-color: black;
  padding: 5px 0 18px;

  &.rendered {
    display: block;
  }

  @include screen('mobile_xl') {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: white;
    justify-content: center;
    align-items: center;

    &.rendered {
      display: flex;
    }
  }
}

.label {
  font-family: $main-font_medium;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3px;
  color: white;

  @include screen('tablet') {
    display: none;
  }
}

.banner {
  background-color: white;
  max-width: 970px;
  max-height: 330px;
  margin: 0 auto;
  overflow: hidden;

  @include screen('mobile_xl') {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;

    iframe {
      width: 100vw !important;
      height: clamp(330px, calc(100vh - 10px), 100vh) !important;
    }
  }
}

.close-btn {
  position: absolute;
  display: none;
  z-index: 100;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #576570;

  &:hover {
    background-color: #44525b;
    border-color: #77818a;

    &::before,
    &::after {
      background-color: white;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #656f78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  @include screen('mobile_xl') {
    display: none;
  }
}
