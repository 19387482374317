@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.annual-chart {
}

.content {
  position: relative;
  width: 100%;
  background-position: 0 0;
  background-repeat: repeat;
  overflow-x: hidden;
  @include screen('mobile_annual_xl') {
    overflow-x: hidden;
  }
}

.fake-preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
}

.rabbi-credits {
  position: fixed;
  left: 50px;
  bottom: 0;
  opacity: 0.8;
  transform: translate(0, 0);
  transition: transform 0.35s;

  @include screen('tablet') {
    opacity: 1;
    left: 0;
    position: relative;
  }

  &-content {
    font-family: $open-sans;
    font-size: 13px;
    line-height: 1.3;
    padding: 20px 40px;
    direction: ltr;
    color: white;

    @include screen('tablet') {
      text-align: left;
      font-size: 14px;
      padding: 0 20px 100px;
    }

    a {
      color: inherit;
      letter-spacing: 0.2px;

      @include screen('tablet') {
        font-weight: bold;
      }
    }
  }
  
  &.without-SEO-text {
    @include screen('tablet') {
      margin-top: -40px;
      // transform: translate(0, -40px) !important;
    }
  }

  &.with-outbrain {
    position: absolute;

    @include screen('tablet') {
      position: relative;
    }
  }
}