@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.header {
  position: relative;
  color: white;

  &.fixed {
    .sub-header-fixed {
      transform: translateY(0);
    }
  }
}

.hero {
  position: relative;
  z-index: 10;
  padding: 72px 60px 85px;
  width: 100%;
  background-color: orangered;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('src/assets/images/header/background-2.png');
  background-size: cover;
  background-position: center;

  @include screen('mobile_xl') {
    height: 330px;
    padding: 0;
    background-image: url('src/assets/images/header/background_mobile.jpg');
  }

  .logo-wrap {
    position: relative;
    width: 100%;
    max-width: 694px;

    @include screen('mobile_xl') {
      width: 100%;
      max-width: 100%;
      padding: 0 16px;
    }

    .logo {
      display: block;
      max-width: 100%;

      @include screen('mobile_xl') {
        max-width: 87%;
        margin: 0 auto;
      }
    }

    h1 {
      font-family: $main-font_light;
      font-size: clamp(40px, 7vw, 45px);
      line-height: 28px;
      text-align: center;
      letter-spacing: 2.1px;
      margin: 0 0 40px -40px;
      white-space: nowrap;

      @include screen('mobile_xl') {
        font-family: $main-font;
        font-weight: 400;
        font-size: 5.55vw;
        line-height: 28px;
        letter-spacing: 0.5px;
        margin: -10px 0 0 -15px;
      }

      @include screen('mobile_lg') {
        font-size: clamp(13px, 7.5vw, 20px);
        margin: -10px 0 0 -12px;
      }
    }

    h2 {
      font-family: $main-font;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.41px;
      text-align: center;

      strong {
        font-family: $main-font_demibold;
      }

      @include screen('mobile_xl') {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.21px;
      }

      &.desktop {
        display: block;

        @include screen('mobile_xl') {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @include screen('mobile_xl') {
          display: block;
          margin: 20px auto 0;
        }
      }
    }

    .sponsor {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      @include screen('mobile_xl') {
        margin-top: 20px;
        gap: 0px;
      }

      p {
        font-size: 30px;
        line-height: 28px;
        letter-spacing: -0.408px;

        @include screen('mobile_xl') {
          font-size: 26px;
          line-height: 41px;
          letter-spacing: -0.41px;
        }
      }

      img {
        display: block;
        width: 78px;
        height: 74px;

        @include screen('mobile_xl') {
          margin-right: 5px;
          width: 60px; // height x 1.05
          height: 57px;
        }
      }
    }
  }
}

.sub-header {
  background-color: $black-color;

  .nav-bar {
    position: relative;
    overflow: hidden;

    .mako-logo-link {
      display: block;
      position: absolute;
      height: 41px;
      left: 10px;
      top: 50%;
      transform: translate(-40px, -50%);

      img {
        height: 100%;
      }

      @include screen('desktop_sm') {
        display: none;
      }
    }

    .logo {
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      height: 30px;
      transform: translate(0, -50%);

      @include screen('desktop_sm') {
        display: none;
      }
    }

    .content-wrap {
      @include screen('mobile_xl') {
        flex-direction: column;
      }
    }

    .tabs {
      display: flex;
      gap: 2px;
      align-items: center;

      @include screen('mobile_xl') {
        width: 100%;
      }

      button {
        position: relative;
        min-width: 92.25px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $black-color_100;

        @include screen('mobile_xl') {
          min-width: 0;
          flex: 1;
        }

        span {
          position: relative;
          z-index: 2;
          font-size: 21px;
          line-height: 31px;
          text-transform: uppercase;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          visibility: hidden;
          transition: opacity, visibility;
          transition-duration: 0.35s;
        }

        &:hover {
          &::before {
            opacity: 0.4;
            visibility: visible;
          }
        }

        &.main-chart {
          &::before {
            background: linear-gradient(
              83.14deg,
              #ff8900 -1.98%,
              #fe8203 11.05%,
              #fa6e0a 30.15%,
              #f54d16 53.6%,
              #ed2027 79.65%,
              #ec1c28 81.39%
            );
          }
        }

        &.local-chart {
          &::before {
            background: linear-gradient(72.27deg, #1c61f5 -3.72%, #00d17a 83.13%);
          }
        }

        &.global-chart {
          &::before {
            background: linear-gradient(
              72.27deg,
              #7f24d1 -2.02%,
              #7b27d2 14.96%,
              #7031d4 30.24%,
              #5c41d8 45.53%,
              #4158dd 60.81%,
              #1f75e3 75.24%,
              #157de5 78.64%
            );
          }
        }

        &.new-songs-chart {
          &::before {
            background: linear-gradient(261.89deg, #ecd402 0.25%, #0bc878 92.36%);
          }
        }

        &.active {
          pointer-events: none;
          span {
            font-family: $main-font_demibold;
          }
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &-fixed {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    height: 55px;
    background-color: #000;
    transform: translateY(calc(-100% - 60px));
    transition: transform;
    transition-duration: 0.35s;

    & > .content-wrap {
      position: relative;
      height: 100%;
      padding: 0 40px;
      max-width: 1084px + 80px;

      @include screen('mobile_xl') {
        padding: 0 12px 0 12px;
      }
    }

    .logos {
      display: flex;
      align-items: center;

      .coke-logo-link {
        @include screen('mobile_xl') {
          display: none;
        }
      }
    }

    .mako-logo-link,
    .coke-logo-link {
      position: relative;
      display: block;
    }

    .coke-logo-link {
      height: 39px;
      display: flex;
      align-items: center;

      @include screen('mobile_xl') {
        width: 32px;
        height: 32px;
      }

      &-fixed {
        display: none;

        @include screen('mobile_xl') {
          display: flex;
          direction: ltr;

          img {
            width: 105%;
            height: 100%;
          }
        }
      }

      .coke-logo {
        height: 39px;
      }
    }

    .mako-logo,
    .coke-logo {
      display: block;
      height: 39px;

      @include screen('mobile_xl') {
        height: 27px;
      }
    }

    .logo {
      position: absolute;
      display: block;
      left: 50%;
      top: 45%;
      height: 35px;
      transform: translate(-50%, -50%);

      @include screen('mobile_xl') {
        height: 24px;
        top: 42%;
      }
    }

    .socials {
      @include screen('mobile_xl') {
        display: none;
      }
    }

    .chart-date {
      display: none;
    }

    .nav-bar {
      background-color: $black-color;

      .chart-date {
        display: flex;

        @include screen('mobile_xl') {
          display: none;
        }
      }
    }

    .top-header {
      display: none;
    }

    @include screen('mobile_xl') {
      background-color: $black-color;
      transform: translateY(calc(-100% - 62px));

      .top-header {
        display: block;
      }
    }
  }
}

.content-wrap {
  max-width: $content-width-desktop;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-date {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 35px;
  white-space: nowrap;

  @include screen('mobile_xl') {
    height: 43px;
  }

  .date {
    position: relative;
    padding-left: 12px;
    margin-left: 12px;
    font-family: $main-font_demibold;
    direction: ltr;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 7px;
      height: 20px;
      width: 1px;
      background-color: white;
    }
  }

  .week {
    position: relative;
  }
}
